import React from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptForm} from '@compt/common/forms/compt-form/compt-form';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {UserSession} from '@compt/types/account';
import {useUpdateUserOnboardingInfoMutation} from '@compt/app/services/api/accounts-slice';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

export interface PreferredNameProps {
  firstName: string | undefined;
}

export const PreferredNameForm = (props: PreferredNameProps) => {
  const [updateUserOnboardingInfo, {isLoading: isUpdating}] = useUpdateUserOnboardingInfoMutation();
  const formMethods = useForm();
  const {handleSubmit, reset} = formMethods;

  const submitForm = (form: FieldValues) => {
    if (isUpdating) {
      return;
    }

    const submission = {...form} as UserSession;
    updateUserOnboardingInfo(submission).then((data) => {
      reset();
      if ('error' in data) {
        triggerCustomToast('error', 'There was a problem updating your preferred name');
        return;
      }
    });
  };

  const fieldSections = [
    {
      fields: [
        {
          fieldType: ComptTextField,
          secret: false,
          id: 'nickname',
          label: 'Preferred name',
          placeholder: 'Preferred name',
          validation: {
            required: false,
          },
          'data-testid': 'nickname-field',
        },
      ],
    },
  ];

  return (
    <>
      <p className="label3 text-center">Have a preferred name?</p>
      <p className="body2 mt-3 mb-8 text-center">
        If something other than {props.firstName}, enter it below; otherwise, you may leave it
        blank.
      </p>
      <ComptForm fieldSections={fieldSections} onSubmit={submitForm} formMethods={formMethods}>
        <ComptButton
          data-testid="preferred-name-submit-button"
          buttonType={ComptButtonType.PRIMARY}
          disabled={isUpdating}
          onClick={handleSubmit(submitForm)}
        >
          Continue
        </ComptButton>
      </ComptForm>
    </>
  );
};
