import React from 'react';

// RTK queries
import {useGetEmployeePreApprovalRequestQuery} from '@compt/app/services/api/employee-learning-development-slice';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';
import {formatCurrencyFromCountryCode} from '@compt/utils/international-helpers';
import {getCustomFieldURLKey} from '@compt/types/learning-development/pre-approval-request';
import {getReimbursementStatus} from '@compt/common/compt-reimbursement-card/compt-reimbursement-card';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptRejectionReason} from '@compt/common/compt-rejection-reason/compt-rejection-reason';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {FileExtensionButton} from '@compt/common/compt-file-extension-button/compt-file-extension-button';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';

// Types
import {LnDReviewStatusFormats} from '@compt/pages/admin-pages/admin-review-lnd/review-lnd.types';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

interface ReimbursementSidePanelProps {
  requestId: string | null;
  companyId: number;
  'data-testid'?: string;
}

export const ReimbursementSidePanel = (props: ReimbursementSidePanelProps) => {
  const [, setSearchParams] = useSearchParams();

  const preApprovalRequestQuery = useGetEmployeePreApprovalRequestQuery(
    {
      companyId: props.companyId,
      requestId: props.requestId,
    },
    {skip: !props.companyId || !props.requestId},
  );

  const preApprovalRequest = preApprovalRequestQuery.data;

  const requestStatus = preApprovalRequest?.status;
  const reimbursementStatus = preApprovalRequest
    ? getReimbursementStatus(preApprovalRequest)
    : null;
  const statusFormat =
    LnDReviewStatusFormats[reimbursementStatus || (requestStatus as LearningRequestStatus)];

  function clearSelectedRequest() {
    setSearchParams({});
  }

  const preApprovalRequestReceiptUrl: string = (
    preApprovalRequest?.request_data[getCustomFieldURLKey('Upload document(s)')] || ''
  ).toString();

  return (
    <>
      <ComptSidePanel
        open={!!props.requestId}
        className="max-w-[464px]"
        data-testid={props['data-testid']}
      >
        <ComptLoadingIndicator
          isLoading={preApprovalRequestQuery.isLoading || preApprovalRequestQuery.isFetching}
        >
          {preApprovalRequest && (
            <>
              <ComptSidePanel.Header
                title={preApprovalRequest.request_data['Vendor name']}
                setOpen={clearSelectedRequest}
                headerIcon={{id: 'file-icon-blue'}}
                statusPill={
                  <span className="flex h-7">
                    <h2 className="text-color-body1">&nbsp;Status</h2>
                    <ComptPill
                      className="ml-2"
                      pillSize={ComptPillSize.MEDIUM}
                      pillType={statusFormat?.statusStyle}
                    >
                      {statusFormat?.label}
                    </ComptPill>
                  </span>
                }
              />
              <ComptSidePanel.Content className="sm:overflow-y-auto">
                <div className="h-full flex flex-col p-6">
                  <div>
                    <h4>{preApprovalRequest.program.name}</h4>
                    <p className="body3 text-color-body1">
                      {preApprovalRequest.program.description}
                    </p>
                  </div>
                  <hr className="my-6" />
                  {/*TODO (COMPT-5766): Implement program activity section*/}
                  {/*<div>*/}
                  {/*  <div className="flex flex-row gap-x-2 items-center">*/}
                  {/*    <ComptSvgIcon iconName="program-activity-icon" />*/}
                  {/*    <h4>Program activity</h4>*/}
                  {/*  </div>*/}
                  {/*  <p className="body3 text-color-body1">{preApprovalRequest.program.description}</p>*/}
                  {/*</div>*/}
                  {/*<hr className="my-6" />*/}
                  <div>
                    <div className="flex flex-row gap-x-2 items-center mb-4">
                      <ComptSvgIcon iconName="message-text-icon" />
                      <h4>Request form</h4>
                    </div>
                    {preApprovalRequest.rejection_reason && (
                      <ComptRejectionReason rejectionReason={preApprovalRequest.rejection_reason} />
                    )}
                    <div className="flex flex-row">
                      <div className="flex flex-col gap-y-2 items-start mb-4 basis-44">
                        <span className="text-color-body1">
                          <p className="label3">Items</p>
                        </span>
                        <span className="border-b w-full pb-2">
                          <p className="body2">Vendor</p>
                        </span>
                        <span className="border-b w-full pb-2">
                          <p className="body2">Amount</p>
                        </span>
                        <span className="border-b w-full pb-2">
                          <p className="body2">Expected completion date</p>
                        </span>
                        <span className="w-full pb-2">
                          <p className="body2">Description and reasons</p>
                        </span>
                      </div>
                      <div className="flex flex-col gap-y-2 items-start mb-4 basis-72">
                        <span className="text-color-body1">
                          <p className="label3">Selection</p>
                        </span>
                        <span className="border-b w-full pb-2">
                          <p className="body2">{preApprovalRequest.request_data['Vendor name']}</p>
                        </span>
                        <span className="border-b w-full pb-2">
                          <p className="body2">
                            {formatCurrencyFromCountryCode(
                              preApprovalRequest.request_data['Amount'],
                              'US',
                            )}
                          </p>
                        </span>
                        <span className="border-b w-full pb-2">
                          <p className="body2">
                            {preApprovalRequest.request_data['Expected completion date']}
                          </p>
                          <br />
                        </span>
                        <span className="w-full pb-2">
                          <p className="body2">
                            {preApprovalRequest.request_data['Description and reason']}
                          </p>
                          <br />
                        </span>
                      </div>
                    </div>
                    <hr className="mb-6" />
                    {preApprovalRequest.expense && (
                      <>
                        <div className="flex flex-row gap-x-2 items-center mb-4">
                          <ComptSvgIcon iconName="message-text-icon" />
                          <h4>Reimbursement form</h4>
                        </div>
                        {preApprovalRequest.expense.rejection_reason && (
                          <ComptRejectionReason
                            rejectionReason={preApprovalRequest.expense.rejection_reason}
                          />
                        )}
                        <div className="flex flex-row">
                          <div className="flex flex-col gap-y-2 items-start mb-4 basis-44">
                            <span className="text-color-body1">
                              <p className="label3">Items</p>
                            </span>
                            <span className="border-b w-full pb-2">
                              <p className="body2">Vendor</p>
                            </span>
                            <span className="border-b w-full pb-2">
                              <p className="body2">Amount</p>
                            </span>
                            <span className="border-b w-full pb-2">
                              <p className="body2">Date of expense</p>
                            </span>
                            <span className="w-full pb-2">
                              <p className="body2">Description and reasons</p>
                            </span>
                          </div>
                          <div className="flex flex-col gap-y-2 items-start mb-4 basis-72">
                            <span className="text-color-body1">
                              <p className="label3">Selection</p>
                            </span>
                            <span className="border-b w-full pb-2">
                              <p className="body2">{preApprovalRequest.expense.vendor_name}</p>
                            </span>
                            <span className="border-b w-full pb-2">
                              <p className="body2">
                                {formatCurrencyFromCountryCode(
                                  preApprovalRequest.expense.amount_of_expense,
                                  'US',
                                )}
                              </p>
                            </span>
                            <span className="border-b w-full pb-2">
                              <p className="body2">{`${preApprovalRequest.expense.date_of_expense}`}</p>
                            </span>
                            <span className="w-full pb-2">
                              <p className="body2">{preApprovalRequest.expense.description}</p>
                              <br />
                            </span>
                          </div>
                        </div>
                        <hr className="mb-6" />
                      </>
                    )}
                    <div>
                      <div className="flex flex-row gap-x-2 items-center mb-4">
                        <ComptSvgIcon iconName="upload-cloud-icon" />
                        <h4>Request form documents</h4>
                      </div>
                      <div className="grid gap-y-2">
                        <FileExtensionButton fileUrl={preApprovalRequestReceiptUrl} />
                        {preApprovalRequest.expense && (
                          <>
                            <div className="flex flex-row gap-x-2 items-center mt-6 pt-6 mb-3 border-t">
                              <ComptSvgIcon iconName="upload-cloud-icon" />
                              <h4>Reimbursement form documents</h4>
                            </div>
                            <FileExtensionButton
                              fileUrl={preApprovalRequest.expense.receipt_image}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ComptSidePanel.Content>
            </>
          )}
          <ComptSidePanel.Footer>
            <ComptButton
              buttonType={ComptButtonType.OUTLINED}
              onClick={clearSelectedRequest}
              onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            >
              Close
            </ComptButton>
          </ComptSidePanel.Footer>
        </ComptLoadingIndicator>
      </ComptSidePanel>
    </>
  );
};
