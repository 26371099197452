/**
 *
 * @param {Object[]} allotments
 * @param {number} categoryId
 * @returns {Object[]}
 */

import {Allotment, AllotmentCycle, AllotmentType} from '@compt/types/allotments';
import {PerkCategory} from '@compt/types/perk-category';

const DEFAULT_STIPEND_NAME = 'Perks Stipend';

export const filterAllotmentsByCategoryId = (allotments: Allotment[], categoryId: number) => {
  if (categoryId && allotments) {
    return allotments.filter((allotment) =>
      allotment.perk_categories.some((category) => category.id === categoryId),
    );
  }

  return allotments;
};

/**
 * Returns {@literal true} if the given allotment has a category with the
 * given category id. Returns {@literal false} otherwise.
 *
 * @param {Allotment} allotment
 * @param {number} categoryId
 * @return {boolean} {@literal true} if the given allotment has a category with the
 * given category id. {@literal false} otherwise.
 */
export const allotmentMapHasCategory = (allotment: Allotment, categoryId: number): boolean => {
  if (categoryId && allotment && allotment.perk_categories) {
    return allotment.perk_categories.some((category) => category.id === categoryId);
  }

  return false;
};

/**
 * Returns {@literal true} if the given allotment has a category with the
 * given category id. Returns {@literal false} otherwise.
 *
 * @param {Object} allotment
 * @param {number} categoryId
 * @return {boolean} {@literal true} if the given allotment has a category with the
 * given category id. {@literal false} otherwise.
 */
export const allotmentObjectHasCategory = (allotment: Allotment, categoryId: number) => {
  if (categoryId && allotment && allotment.perk_categories) {
    return allotment.perk_categories.some((category) => category.id === categoryId);
  }

  return false;
};

export const filterPerkCategoriesByAllotment = (
  allotment: Allotment,
  categories: PerkCategory[],
) => {
  if (categories && allotment) {
    return categories.filter((category) =>
      allotment.perk_categories.some((allotmentCategory) => allotmentCategory.id === category.id),
    );
  }

  return [];
};

export const findIntersectingCategories = (allotments: Allotment[]) => {
  if (allotments && allotments.length > 0) {
    if (allotments.length === 1) {
      return allotments[0].perk_categories;
    }

    return allotments.reduce(
      (acc, allotment) => filterPerkCategoriesByAllotment(allotment, acc),
      allotments[0].perk_categories,
    );
  }

  return [];
};

/**
 * Takes a list of allotments or a single allotment and returns the cycle name(s)
 * @param allotments a single allotment or a list of allotments
 * @return {string|string}
 */
export const getAllotmentStipendName = (allotments: Allotment[]) => {
  if (allotments) {
    return allotments.reduce((acc, allotment, index) => {
      const name = allotment.cycle ? allotment.cycle.name : DEFAULT_STIPEND_NAME;

      if (index > 0) {
        return `${acc}, ${name}`;
      }

      return `${name}`;
    }, '');
  }

  return DEFAULT_STIPEND_NAME;
};

/**
 * Takes an allotment and returns whether it is recurring or not.
 * @param allotment a single allotment
 * @return {boolean}
 */
export const isRecurringAllotment = (allotment: Allotment) => {
  const nonRecurringTypes = [2, 4, 5]; // Bonus & Recognition to Receive, New Hire, Anniversary
  return !(
    nonRecurringTypes.includes(allotment.type) ||
    nonRecurringTypes.includes(allotment.sub_type as number)
  );
};

export const getAllotmentCyclePeriodDescription = (months: number): string => {
  let desc: string;
  switch (months) {
    case 1:
      desc = 'Monthly';
      break;
    case 3:
      desc = 'Quarterly';
      break;
    case 6:
      desc = 'Semiannual';
      break;
    case 12:
      desc = 'Annual';
      break;
    default:
      desc = `${months} months`;
  }
  return desc;
};

export const getAllotmentCycleTypeName = (cycle: AllotmentCycle): string => {
  const TEAM_RECOGNITION_TYPE_NAME = 'Team Recognition';
  const NON_MONETARY_TYPE_NAME = 'Shout Out';
  const SPOT_BONUS_STIPEND_TYPE = 'Spot Bonus';
  const NEW_HIRE_STIPEND_TYPE_NAME = 'New Hire';
  const ANNIVERSARY_STIPEND_TYPE_NAME = 'Anniversary Bonus';

  let typeName: string;

  switch (cycle.type) {
    case 1:
      switch (cycle.sub_type) {
        case 1:
          typeName = getAllotmentCyclePeriodDescription(cycle.months);
          break;
        case 2:
          typeName = getAllotmentCyclePeriodDescription(cycle.months);
          break;
        case 3:
          typeName = NON_MONETARY_TYPE_NAME;
          break;
        default:
          typeName = getAllotmentCyclePeriodDescription(cycle.months);
      }
      break;
    case 2:
      typeName = SPOT_BONUS_STIPEND_TYPE;
      break;
    case 4:
      typeName = NEW_HIRE_STIPEND_TYPE_NAME;
      break;
    case 5:
      typeName = ANNIVERSARY_STIPEND_TYPE_NAME;
      break;
    default:
      typeName = 'Unrecognized Stipend Type';
  }
  return typeName;
};

export const ALLOTMENT_CYCLE_FUNDING_MODES = {
  AllUpFront: 1,
  AccrualMonthly: 2,
  UpfrontWithProrating: 3,
  NoFunding: 4,
  AccrualSemiAnnual: 5,
};

export const getAccrualDescription = (cycle: AllotmentCycle) =>
  cycle && cycle.funding_mode === ALLOTMENT_CYCLE_FUNDING_MODES.AccrualMonthly
    ? '(Monthly Accrual)'
    : cycle.funding_mode === ALLOTMENT_CYCLE_FUNDING_MODES.AccrualSemiAnnual
    ? '(Semiannual Accrual)'
    : null;
