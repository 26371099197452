import React, {useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetCustomNotificationsQuery} from '@compt/app/services/api/custom-notifications-slice';

// Hooks and methods
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {AdminCustomizableNotificationsController} from './admin-customizable-notifications-page.controller';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';

// Types
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {CustomNotification} from '@compt/types/custom-notifications';

// Components
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptRow} from '@compt/common/compt-row/compt-row';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {CreateCustomNotificationsSidePanel} from './components/create-custom-notifications-side-panel';
import {ExistingCustomNotificationsSidePanel} from './components/existing-custom-notifications-side-panel';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';

const controller = AdminCustomizableNotificationsController;

export const AdminCustomizableNotificationsPage = () => {
  const [createSidePanelOpen, setCreateSidePanelOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<CustomNotification | null>(null);

  const isMobileView = useIsMobileView();

  const sessionQuery = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);
  const customNotificationQuery = useGetCustomNotificationsQuery(
    companyQuery.data?.id ?? skipToken,
  );

  const hasRequiredRole = useHasRequiredRole([USER_ROLES.admin]);

  const columnDefinition = controller.getColumnDefinition(onActionClicked);

  function onActionClicked(notification: CustomNotification) {
    setSelectedNotification(notification);
  }

  if (!hasRequiredRole || !customNotificationQuery.data?.results || !companyQuery.data) {
    return <Error404Page />;
  }

  return (
    <>
      <CreateCustomNotificationsSidePanel
        open={createSidePanelOpen}
        setOpen={setCreateSidePanelOpen}
        company={companyQuery.data}
        existingNotifications={customNotificationQuery.data?.results}
      />
      <ExistingCustomNotificationsSidePanel
        selectedNotification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
        company={companyQuery.data}
        existingNotifications={customNotificationQuery.data?.results}
      />
      <ComptPage
        title="Customizable notifications"
        subtitle="View or edit custom notifications"
        includeBottomHR={false}
        action={
          <>
            {!isMobileView && (
              <ComptButton
                iconId={ComptButtonIcon.PLUS}
                buttonType={ComptButtonType.PRIMARY}
                onClick={() => setCreateSidePanelOpen(true)}
                className={`block ${isMobileView && 'w-full my-200'}`}
              >
                Add custom notification
              </ComptButton>
            )}
          </>
        }
      >
        <>
          {companyQuery.data && customNotificationQuery?.data.results && (
            <ComptRow className={`${isMobileView && 'mt-300'}`}>
              <ComptTable
                company={companyQuery.data}
                tableId={'custom-notifications-table'}
                className="w-full"
                data={customNotificationQuery.data?.results}
                dataLoading={customNotificationQuery.isLoading}
                noDataTitleText="No custom notifications have been added yet"
                noDataSubtitle={
                  <p className="body1 text-center">
                    You haven&apos;t added any custom notifications yet. Click on &quot;Add custom
                    notification&quot; to get started!
                  </p>
                }
                allowShowHide={false}
                columnDefinition={columnDefinition}
                allowPagination={false}
                totalCount={customNotificationQuery.data?.results.length}
                onRowClicked={(notification) => setSelectedNotification(notification)}
                fixedHeader={false}
                stickyLastColumn
              />
            </ComptRow>
          )}
        </>
      </ComptPage>
      {isMobileView && (
        <div
          className={`fixed bottom-0 bg-white left-1/2 transform z-10
          -translate-x-1/2 w-full p-300 border-t border-t-gray-300`}
        >
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            iconId={ComptButtonIcon.PLUS}
            onClick={() => setCreateSidePanelOpen(true)}
            data-testid="compt-create-custom-notification-mobile-buttom"
            className="w-full"
          >
            Add custom notification
          </ComptButton>
        </div>
      )}
    </>
  );
};
