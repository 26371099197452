import {logS3UploadError} from '@compt/app/services/account';
import {fetchSignedUrl, getS3ObjectHead} from '@compt/app/services/s3.service';
import {
  ComptReceiptUploadProps,
  FileUploadFile,
  FileUploadInfo,
  FileUploadState,
  initialFileState,
} from '@compt/types/file-upload';
import {FeatureFlags, featureEnabled} from '@compt/utils/feature-flags-helper';
import {AVScanStatus, S3_AV_STATUS_HEADER_KEY} from '@compt/utils/image-helpers';
import {Dispatch, SetStateAction} from 'react';
import {
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetError,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';

type SetFileUploadState = Dispatch<SetStateAction<FileUploadState>>;

export class ComptReceiptUploadController {
  constructor(
    public fieldName: string,
    public setFileUploadState: SetFileUploadState,
    public resetField: UseFormResetField<FieldValues>,
    public setError: UseFormSetError<FieldValues>,
    public clearError: UseFormClearErrors<FieldValues>,
    public getValues: UseFormGetValues<FieldValues>,
    public unregister: UseFormUnregister<FieldValues>,
    public previewReceipt?: boolean,
    public onKeyChange?: (
      receiptKey: string | null,
      getValues: UseFormGetValues<FieldValues>,
      unregister: UseFormUnregister<FieldValues>,
      resetField: UseFormResetField<FieldValues>,
    ) => void,
    public onDelete?: () => void,
  ) {}

  getSignedUrl = (
    props: ComptReceiptUploadProps,
    file: FileUploadFile,
    callback: (data: {headUrl: string}) => void,
  ) => {
    fetchSignedUrl(
      file,
      (data: any) => {
        this.onSignedUrlSuccess(data);
        callback(data);
      },
      (error: any) => this.setUploadError(error),
      props.domain,
      props.additionalParams,
      props.token,
    );
  };

  onSignedUrlSuccess = (data: {headUrl: string}) => {
    if (data && 'headUrl' in data && !!data.headUrl) {
      this.setFileUploadState((prevState) => ({
        ...prevState,
        s3FileHeadUrl: data.headUrl as string,
      }));
    }
  };

  preprocessFile = (fileToProcess: FileUploadFile, next: (file: FileUploadFile) => void) => {
    const MAX_FILE_SIZE = 12 * 1024 * 1024; /** 12 Mbytes */

    if (!!fileToProcess.size && fileToProcess.size > MAX_FILE_SIZE) {
      this.setError(this.fieldName, {
        type: 'custom',
        message: 'The selected upload exceeds file size limit of 12MB',
      });
      return;
    }

    next(fileToProcess);
  };

  onUploadProgress = (progress: number) => {
    this.setFileUploadState((prevState) => ({...prevState, isUploading: true}));
    this.incrementProgress(progress);
  };

  incrementProgress = (progress: number) => {
    this.setFileUploadState((prevState) => {
      if (progress === 0 || (progress && progress < 99)) {
        return {
          ...prevState,
          uploadProgress: Math.min(progress + 1, 99),
        };
      }

      return prevState;
    });
  };

  onUploadFinish = (info: FileUploadInfo) => {
    if (!!info.headUrl && featureEnabled(FeatureFlags.ADP_INTEGRATION)) {
      this.setScanStatus(AVScanStatus.SCANNING);
      this.checkForAVScan(info.headUrl, info);
      return;
    }

    this.confirmReceipt(info);
  };

  setUploadError = (errorMessage: string) => {
    this.setError(this.fieldName, {
      type: 'custom',
      message: 'Error uploading file',
    });
    this.setFileUploadState((prevState) => ({
      ...prevState,
      isUploading: false,
      isSuccess: false,
      error: errorMessage,
    }));
    logS3UploadError(errorMessage);
  };

  confirmReceipt = (incomingReceiptImage: FileUploadInfo) => {
    this.clearError(this.fieldName);
    this.resetField(this.fieldName, {defaultValue: incomingReceiptImage.key});

    if (this.onKeyChange) {
      this.onKeyChange(incomingReceiptImage.key, this.getValues, this.unregister, this.resetField);
    }

    this.setFileUploadState((prevState) => ({
      ...prevState,
      fileName: this.previewReceipt
        ? incomingReceiptImage.file.preview
        : incomingReceiptImage.file.name,
      fileSize: incomingReceiptImage.file.size,
      fileType: incomingReceiptImage.file.type,
      isUploading: false,
      isSuccess: true,
      hasErrors: false,
    }));
  };

  deleteFile = () => {
    this.clearError(this.fieldName);
    this.resetField(this.fieldName, {defaultValue: null});
    this.setFileUploadState((prevState) => ({...prevState, ...initialFileState}));

    if (this.onKeyChange) {
      this.onKeyChange(null, this.getValues, this.unregister, this.resetField);
    }
  };

  setScanStatus = (status: AVScanStatus) => {
    this.setFileUploadState((prevState) => ({...prevState, scanStatus: status}));
  };

  checkForAVScan = (s3FileHeadUrl: string | null, info: FileUploadInfo) => {
    if (!s3FileHeadUrl) {
      return;
    }
    getS3ObjectHead(s3FileHeadUrl).then((response: any) => {
      const scanStatus = response.headers[S3_AV_STATUS_HEADER_KEY];
      const errorMessage =
        'The file you are trying to upload is infected. Please try another file.';

      switch (scanStatus) {
        case AVScanStatus.CLEAN:
          this.setFileUploadState((prevState) => ({
            ...prevState,
            isUploading: false,
          }));
          this.confirmReceipt(info);
          this.setScanStatus(AVScanStatus.CLEAN);
          break;

        case AVScanStatus.INFECTED:
          this.setUploadError(errorMessage);
          this.setScanStatus(AVScanStatus.INFECTED);
          break;

        default:
          setTimeout(() => this.checkForAVScan(s3FileHeadUrl, info), 1000);
      }
    });
  };
}
