import React from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptForm} from '@compt/common/forms/compt-form/compt-form';
import {UserSession} from '@compt/types/account';
import {ComptCheckboxField} from '@compt/common/forms/compt-checkbox-field/compt-checkbox-field';
import {useUpdateUserOnboardingInfoMutation} from '@compt/app/services/api/accounts-slice';
import {ComptLink} from '@compt/common/compt-link/compt-link';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

export interface NotificationPreferenceProps {
  hasSlackToken: boolean | undefined;
  canEnableSlack: boolean | undefined;
  userUid: string | undefined;
  baseUrl: string | undefined;
  slackClientId: string | undefined;
}

export const NotificationPreferenceForm = (props: NotificationPreferenceProps) => {
  const [updateUserOnboardingInfo, {isLoading: isUpdating}] = useUpdateUserOnboardingInfoMutation();
  const formMethods = useForm({mode: 'onChange'});
  const {handleSubmit, reset, watch} = formMethods;
  const {hasSlackToken} = props;
  const submitForm = (form: FieldValues) => {
    if (isUpdating) {
      return false;
    }

    const submission = {...form} as UserSession;

    updateUserOnboardingInfo(submission).then((data) => {
      reset();
      if ('error' in data) {
        triggerCustomToast('error', 'There was a problem updating your notification preference');
        return;
      }
    });
  };

  const values = watch(['email_notification', ...(hasSlackToken ? ['slack_notification'] : [])]);
  // On initial page load, the values are undefined even though email_notification defaults to true and is visually
  // checked. Avoid the "At least one option" error message from showing incorrectly.
  const optionSelected = values.includes(undefined) || values.includes(true);

  const fieldSections = [
    {
      fields: [
        {
          fieldType: ComptCheckboxField,
          id: 'email_notification',
          label: 'Email Notification',
          initialValue: true,
          excludeLabelText: true,
          validation: {
            required: !hasSlackToken && `Required if you are not adding Compt${"'"}s Slack app.`,
          },
          'data-testid': 'email-notification-field',
        },
        ...(hasSlackToken
          ? [
              {
                fieldType: ComptCheckboxField,
                id: 'slack_notification',
                label: 'Slack Notification',
                excludeLabelText: true,
                validation: {
                  required: false,
                },
                'data-testid': 'slack-notification-field',
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <>
      <p className="body1 text-center">Notification Channels</p>
      <p className="body2 mt-3 mb-8 text-center">
        Tell us how best to reach you with relevant Compt information related to your account.
      </p>
      <ComptForm fieldSections={fieldSections} onSubmit={submitForm} formMethods={formMethods}>
        <div>
          {!props.canEnableSlack && (
            <div className="flex flex-col">
              <ComptLink
                className="flex justify-center mb-1"
                data-testid="notification-add-slack-link"
                // eslint-disable-next-line max-len
                link={`https://slack.com/oauth/v2/authorize?client_id=${props.slackClientId}&user_scope=channels:write,chat:write&state=${props.userUid},/app&redirect_uri=${props.baseUrl}/slack`}
              >
                Add Compt&apos;s Slack App!
              </ComptLink>
              <span className="label3 mb-3 text-center flex justify-center">
                Learn more
                <ComptLink newTab={true} link="/slack/info" className="ml-1">
                  here
                </ComptLink>
                .
              </span>
            </div>
          )}
          <ComptButton
            data-testid="notification-preference-submit-button"
            className="w-full"
            buttonType={ComptButtonType.PRIMARY}
            disabled={!optionSelected || isUpdating}
            onClick={handleSubmit(submitForm)}
          >
            Continue
          </ComptButton>
        </div>
      </ComptForm>
      {!optionSelected && hasSlackToken && (
        <p className="body3 text-red-500 text-center">At least one option must be selected.</p>
      )}
    </>
  );
};
