import React, {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {Float} from '@headlessui-float/react';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export interface SimpleActionNoData {
  label: string;
  onClick: () => void;
  textColor?: string | undefined;
}

export interface SimpleActionWithData<TActionItem> {
  label: string;
  onClick: (item: TActionItem) => void;
  textColor?: string;
}

export type SimpleAction<TActionItem> = TActionItem extends undefined
  ? SimpleActionNoData
  : SimpleActionWithData<TActionItem>;

export interface SimpleActionMenuProps<TActionItem = undefined> {
  relatedActionItem?: TActionItem;
  actions: Array<SimpleAction<TActionItem>>;
}

export const SimpleMenuItem = <TActionItem = undefined,>({
  item,
  action,
  textColor,
}: {
  item: TActionItem;
  action: SimpleAction<TActionItem>;
  textColor: string | undefined;
}) => (
  <Menu.Item>
    <div className="hover:bg-gray-200">
      <ComptButton
        buttonType={ComptButtonType.BORDERLESS}
        textClassName={textColor}
        onClick={() => action.onClick(item)}
      >
        {action.label}
      </ComptButton>
    </div>
  </Menu.Item>
);

/**
 * A simple menu that displays a list of options to click on.
 *
 * @param relatedActionItem (optional) the item to pass to the option callable.
 * @param actions the actions to display
 */
export const SimpleActionMenu = <TActionItem = undefined,>({
  relatedActionItem,
  actions,
}: SimpleActionMenuProps<TActionItem>) => (
  <Menu as="div" className="relative inline-block text-left">
    <Float portal flip placement="bottom">
      <div>
        <Menu.Button>
          <button className="p-3">
            <ComptSvgIcon iconName="ellipse-vertical-icon" />
          </button>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            'z-10 mt-2 w-full min-w-max origin-top-right divide-y divide-gray-100 ' +
            'rounded-md bg-gray-25 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          }
        >
          {actions.map((action, index) => (
            <SimpleMenuItem<TActionItem>
              key={index}
              item={relatedActionItem as TActionItem}
              action={action}
              textColor={action.textColor}
            />
          ))}
        </Menu.Items>
      </Transition>
    </Float>
  </Menu>
);
