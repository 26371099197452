import React from 'react';

// Hooks and methods
import {UseFormReturn} from 'react-hook-form';

// Components
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';

// Types
import {ProgramOverviewFieldValues} from './program-overview.types';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';

interface ProgramOverviewFieldProps {
  formMethods: UseFormReturn<ProgramOverviewFieldValues>;
}

export const ProgramOverviewFields = (props: ProgramOverviewFieldProps) => {
  const watchedDescription = props.formMethods.watch('description');

  return (
    <div className="max-w-2xl">
      <ComptTextField
        name="name"
        label="Program name"
        data-testid="program-name-field"
        control={props.formMethods.control}
        register={props.formMethods.register}
        validation={{required: 'Program name is required'}}
        placeholder={'Give your program a name'}
        errors={props.formMethods.formState.errors.name}
        autoComplete="off"
      />
      <ComptTextAreaField
        name="description"
        label="Description"
        data-testid="program-description"
        placeholder="Enter a description..."
        validation={{required: 'Program description is required'}}
        register={props.formMethods.register}
        errors={props.formMethods.formState.errors.description}
        watchedValue={watchedDescription}
        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
        rows={5}
      />
    </div>
  );
};
