import React, {useState} from 'react';

// Components
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ReviewLnDTable} from './components/review-lnd-table.container';

export const AdminReviewLnDPage = () => {
  const [activeTab, setActiveTab] = useState('learning-development');
  const [_isSidebarCollapsed, _setIsSidebarCollapsed] = useState(false);
  const [selectedStatuses, _setSelectedStatuses] = useState<string[]>([]);

  return (
    <div className="flex h-full">
      <ComptPage
        title="Review"
        subtitle="Manage stipends for employees"
        className="flex-grow h-full"
        includeBottomHR={false}
        bottomHRClassName="mb-0 mt-400 sm:mt-000"
      >
        <div className="flex mb-6">
          <button
            className={`py-2 text-lg font-medium ${
              activeTab === 'learning-development'
                ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                : 'text-gray-500'
            }`}
            onClick={() => setActiveTab('learning-development')}
          >
            Learning & development
          </button>
        </div>
        <div>
          {activeTab === 'learning-development' && (
            <ReviewLnDTable selectedStatuses={selectedStatuses} />
          )}
        </div>
      </ComptPage>
      {/* TO-DO: Implement in COMPT-5767 */}
      {/* <div
        className={`
          h-full border-l border-stroke-divider1 flex flex-col overflow-y-auto transition-all
          ${isSidebarCollapsed ? 'w-[60px] min-w-[60px]' : 'w-[232px] min-w-[232px]'}
        `}
      >
        {isSidebarCollapsed ? (
          <div className="flex justify-center items-start h-full mt-4">
            <button
              onClick={() => setIsSidebarCollapsed(false)}
              aria-label="Show filter"
              className="flex items-center justify-center"
            >
              <ComptSvgIcon iconName="filter-lines-blue-icon" />
            </button>
          </div>
        ) : (
          <ReviewLndFilterSidebar
            onHide={() => setIsSidebarCollapsed(true)}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
          />
        )}
      </div> */}
    </div>
  );
};
