import {Dispatch, SetStateAction} from 'react';
import {
  ControllerRenderProps,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetError,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';
import {ReactHookFormErrors} from '@compt/types/form/react-hook-form-helper-types';
import {ComptFormControlBaseProps} from './form/compt-forms';
import {AVScanStatus} from '@compt/utils/image-helpers';

export enum FileUploadStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export const initialFileState = {
  fileName: null,
  fileSize: null,
  scanStatus: null,
  uploadProgress: 0,
  isSuccess: false,
  isUploading: false,
  errors: null,
  s3FileHeadUrl: null,
};

export interface FileUploadFile {
  name?: string;
  size?: number;
  preview?: string;
  type?: string;
}

export interface FileUploadInfo {
  file: FileUploadFile;
  fileUrl: string;
  headUrl: string;
  key: string;
  signedUrl: string;
}

export interface ComptReceiptUploadProps extends ComptFormControlBaseProps<string, string> {
  ['data-testid']?: string;
  errors: ReactHookFormErrors;
  field: ControllerRenderProps;
  file: FileUploadFile | null;
  resetField: UseFormResetField<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearError: UseFormClearErrors<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  domain?: string;
  userId?: number;
  additionalParams?: object;
  token?: string;
  previewReceipt?: boolean;
  previousReceipt?: string;
  onKeyChange?: (
    receiptKey: string | null,
    getValues: UseFormGetValues<FieldValues>,
    unregister: UseFormUnregister<FieldValues>,
    resetField: UseFormResetField<FieldValues>,
  ) => void;
}

export interface FileUploadState {
  fileName: string | null | undefined;
  fileSize: number | null | undefined;
  fileType: string | null | undefined;
  scanStatus: AVScanStatus | null;
  isUploading: boolean;
  uploadProgress: number;
  isSuccess: boolean;
  errors: string | null;
  s3FileHeadUrl: string | null;
}

export type SetFileUploadState = Dispatch<SetStateAction<FileUploadState>>;
