import React, {useEffect} from 'react';

// Hooks and methods
import toast from 'react-hot-toast';

// Types
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {VendorDiscovery} from '@compt/types/vendor-discovery/vendor-discovery';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptLink} from '@compt/common/compt-link/compt-link';

export interface VendorOverviewProps {
  id: string;
  title: string;
  subtitle?: string;
  vendor: VendorDiscovery | null;
  open: boolean;
  setOpen: (vendor: null) => void;
}

export const VendorOverviewSidePanel = (props: VendorOverviewProps) => {
  const {open, setOpen, vendor} = props;

  // Close any toast messages that may be open.
  useEffect(() => {
    if (open) {
      toast.remove();
    }
  }, [open]);

  return (
    <ComptSidePanel open={props.open}>
      <ComptSidePanel.Header
        title={props.title}
        setOpen={() => setOpen(null)}
        headerIcon={{id: 'file-icon-blue'}}
      />
      <ComptSidePanel.Content>
        <div className="h-full flex flex-col px-6 pt-6 gap-y-6">
          <div className="flex justify-between">
            <div className="flex gap-x-4">
              <img
                src={vendor?.logo_image}
                alt={vendor?.name}
                className="rounded-xl shadow-[0px_6px_20px_0px_rgba(0,0,0,0.08)]
              object-cover h-[72px] w-[72px]"
              />
              <div className="flex flex-col gap-y-2">
                <p className="heading2">{vendor?.name}</p>
                <div className="flex -space-x-2">
                  {vendor?.perk_categories?.map(({internal_id, id}) => (
                    <ComptSvgIcon
                      key={id}
                      iconName={internal_id}
                      isCategoryIcon
                      svgProp={{width: '20px', height: '20px'}}
                      className="w-8 h-8 border-2 border-white bg-[white] rounded-full
            flex items-center justify-center"
                    />
                  ))}
                </div>
              </div>
            </div>
            {vendor?.website && (
              <ComptLink link={vendor?.website} newTab>
                <ComptButton
                  buttonType={ComptButtonType.PRIMARY}
                  iconId="external-link-icon"
                  trailingIcon
                >
                  Go to site
                </ComptButton>
              </ComptLink>
            )}
          </div>
          <p className="body1">{vendor?.short_description}</p>
          <hr />
          {vendor?.offer && (
            <div>
              <div className="flex flex-row gap-x-2 items-center">
                <ComptSvgIcon iconName="sale-icon" />
                <h4>Offer available</h4>
              </div>
              <p className="body3">{vendor?.offer}</p>
              <hr className="mt-6" />
            </div>
          )}
          {vendor?.long_description && (
            <div>
              <div className="flex flex-row gap-x-2 items-center">
                <ComptSvgIcon iconName="message-text-icon" />
                <h4>Full description</h4>
              </div>
              <p className="body3">{vendor?.long_description}</p>
            </div>
          )}
        </div>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="grid grid-flow-col gap-3 sm:justify-start w-full bg-white">
          <ComptButton
            buttonType={ComptButtonType.OUTLINED}
            onClick={() => {
              setOpen(null);
            }}
          >
            Close
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
