import React from 'react';
import {PaginationChangePage} from 'react-data-table-component/dist/src/DataTable/types';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export interface ComptMobilePaginationProps {
  totalPages: number;
  itemsPerPage: number;
  onChangePage: PaginationChangePage;
  currentPage: number;
}

export const ComptMobilePagination = (props: ComptMobilePaginationProps) => (
  <div className="flex justify-between items-center border-t">
    <button
      className="p-4 border border-transparent rounded relative inline-flex items-center"
      onClick={() => props.onChangePage(props.currentPage - 1, props.itemsPerPage)}
      disabled={props.currentPage === 1}
    >
      <span className="sr-only">Previous</span>
      {props.currentPage !== 1 && (
        <ComptSvgIcon
          iconName="arrow-left"
          labelHidden={false}
          ariaLabel="arrow-left"
          className="h-5 w-5"
        />
      )}
    </button>
    <p className="compt-text--md compt-text--regular">
      Page <b className="compt-text--medium">{props.currentPage}</b> of{' '}
      <b className="compt-text--medium">{props.totalPages}</b>
    </p>
    <button
      className="p-4 border border-transparent rounded relative inline-flex items-center"
      onClick={() => {
        props.onChangePage(props.currentPage + 1, props.itemsPerPage);
      }}
      disabled={props.currentPage === props.totalPages}
    >
      <span className="sr-only">Next</span>
      {props.currentPage !== props.totalPages && (
        <ComptSvgIcon
          iconName="arrow-right"
          labelHidden={false}
          ariaLabel="arrow-right"
          className="h-5 w-5"
        />
      )}
    </button>
  </div>
);
