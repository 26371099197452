import React, {HTMLAttributes, ReactNode} from 'react';

export interface ComptCardProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
}

export const ComptCard = (props: ComptCardProps) => (
  <div
    className="compt-card flex flex-col flex-start flex-[1_0_0%]
  border border-gray-300 rounded-xl"
  >
    {props.children}
  </div>
);
