import React, {useContext, useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import {AVScanStatus, S3_AV_STATUS_HEADER_KEY} from '@compt/utils/image-helpers';
import {ComptReceiptUploadProps, FileUploadFile, FileUploadState} from '@compt/types/file-upload';
import {ComptReceiptUploadController} from './compt-receipt-upload.controller';
import {UploadErrorComponent} from './compt-upload-error-component';
import {UploadedFileComponent} from './compt-uploaded-file-component';
import {ComptUploadedReceipt} from '@compt/common/compt-uploaded-receipt/compt-uploaded-receipt';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';

export const initialFileState = {
  fileName: null,
  fileSize: null,
  fileType: null,
  scanStatus: null,
  uploadProgress: 0,
  isSuccess: false,
  isUploading: false,
  errors: null,
  s3FileHeadUrl: null,
};

export const ComptReceiptUpload = (props: ComptReceiptUploadProps) => {
  const {resetField, setError, clearError, onKeyChange, getValues, unregister} = props;
  const [fileUploadState, setFileUploadState] = useState<FileUploadState>({
    ...initialFileState,
    fileName: props.initialValue,
  });
  const {receiptUploading, setReceiptUploading} = useContext(ReceiptUploadContext);

  const controller = new ComptReceiptUploadController(
    props.field.name,
    setFileUploadState,
    resetField,
    setError,
    clearError,
    getValues,
    unregister,
    props.previewReceipt,
    onKeyChange,
  );

  const containerClasses = `flex flex-col w-full h-full rounded-xl
  ${
    props.errors || fileUploadState.errors
      ? 'border border-stroke-critical focus:border-stroke-critical focus:border'
      : 'focus:ring-stroke-focus border border-gray-300 bg-white'
  }
  ${
    props.previewReceipt
      ? 'justify-center'
      : props.errors || fileUploadState.errors
      ? 'justify-between'
      : ''
  }
  `;

  useEffect(() => {
    if (fileUploadState.isUploading) {
      setReceiptUploading(() => true);
    } else {
      setReceiptUploading(() => false);
    }
  }, [fileUploadState.isUploading]);

  return (
    <>
      <div tabIndex={-1} className={containerClasses} ref={props.field.ref}>
        {/* Displays when file upload error has occurred */}
        {fileUploadState.fileName && fileUploadState.errors && (
          <UploadErrorComponent fileName={fileUploadState.fileName} controller={controller} />
        )}
        {/* Displays when file upload is in progress */}
        {fileUploadState.isUploading && (
          <UploadedFileComponent controller={controller} fileUploadState={fileUploadState} />
        )}
        {/* Displays when no upload is in progress and file exists */}
        {!fileUploadState.isUploading && fileUploadState.fileName && (
          <div className="flex flex-row justify-center w-full h-full relative">
            <ComptUploadedReceipt
              receiptImage={`${fileUploadState.fileName}`}
              fileType={fileUploadState.fileType}
            />
            {!props.disabled && (
              <button
                type="button"
                onClick={() => {
                  controller.deleteFile();
                }}
                className={
                  'shadow-none absolute right-0 top-0 mt-2 mr-2 h-6 w-6 bg-red-600 rounded-full p-1.5'
                }
              >
                <ComptSvgIcon
                  className="flex justify-center mb-3"
                  iconName="trash-icon-white"
                  svgProp={{width: '12px', height: '12px'}}
                />
              </button>
            )}
          </div>
        )}
        {/* Displays when no file is uploaded. Hidden when a file is uploading, or a file exists */}
        <DropzoneS3Uploader
          className={twMerge(`flex flex-col upload-file items-center justify-center h-full cursor-pointer
            ${fileUploadState.isUploading || fileUploadState.fileName ? 'hidden' : ''} ${
              props.disabled ? 'cursor-not-allowed' : ''
            }`)}
          style={{dropzone: ''}}
          s3Url="https://s3.amazonaws.com/"
          onProgress={controller.onUploadProgress}
          onFinish={controller.onUploadFinish}
          onError={controller.setUploadError}
          accept="image/jpeg,image/png,image/gif,image/bmp,image/tiff,application/pdf"
          multiple={false}
          passChildrenProps={false}
          disabled={props.disabled}
          upload={{
            preprocess: controller.preprocessFile,
            signingUrl: '/s3/sign',
            s3path: `${props.userId}/receipts/`,
            signingUrlMethod: 'GET',
            multiple: false,
            contentDisposition: 'inline',
            getSignedUrl: (file: FileUploadFile, callback: () => void) =>
              controller.getSignedUrl(props, file, callback),
            uploadRequestHeaders: {
              [S3_AV_STATUS_HEADER_KEY]: AVScanStatus.NOT_STARTED,
            },
          }}
        >
          {!fileUploadState.isUploading && !fileUploadState.fileName && <UploadLink />}
        </DropzoneS3Uploader>
      </div>
      <div hidden={!fileUploadState.scanStatus}>
        {renderScanMessage(fileUploadState.scanStatus)}
      </div>
    </>
  );
};

const UploadLink = () => (
  <div className="flex flex-col justify-center my-6">
    <ComptSvgIcon
      className="flex justify-center mb-3"
      iconName="upload-icon"
      svgProp={{width: '40px', height: '40px'}}
    />
    <div className="flex justify-center">
      <p className="label4 text-color-link">Click to upload</p>
      <p className="body3 text-color-body2 ml-1">or drag and drop</p>
    </div>
    <p className="body3 text-color-body2 flex justify-center">PDF, PNG, or JPG</p>
  </div>
);

const renderScanMessage = (status: AVScanStatus | null) => {
  switch (status) {
    case AVScanStatus.CLEAN:
      return (
        <p className="body3 text-color-body2 mt-2">
          Your file has been uploaded and scanned successfully.
        </p>
      );
    case AVScanStatus.SCANNING:
      return (
        <p className="body3 text-color-body2 mt-2">
          An extra layer of security is required due to an integration your company is using. This
          scan may take up to a minute, so please complete the remaining fields meanwhile. Thanks
          for your patience!
        </p>
      );
    default:
      return null;
  }
};
