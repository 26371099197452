import {Allotment} from '@compt/types/allotments';
import {FieldValues} from 'react-hook-form';
import {PerkCategory} from '@compt/types/perk-category';
import {HelpScout} from '@compt/utils/analytics/vendors/help-scout';
import {Analytics} from '@compt/utils/analytics/analytics-helpers';
import {EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL} from '@compt/utils/analytics/types';
import {EXPENSE_STATUS_INFO, ExpenseStatus} from '@compt/types/stipend-expense';

export interface SaveClaimFormFieldValues extends FieldValues {
  perk_category: PerkCategory | null;
  allotment: Allotment | null;
  receipt_key: string;
  date_of_expense: Date | undefined;
  vendor: string;
  description: string;
  amount_of_expense: number | string;
  slack_message?: string;
  ms_teams_message?: string;
}

export class SaveClaimSidePanelController {
  static showRelevantForcedMessages = (
    categoryId: string | number | undefined,
    allotmentCycleId: number | undefined,
    companyId: number | undefined,
  ) => {
    if (!allotmentCycleId || !categoryId || !companyId) {
      return false;
    }

    const helpScout = new HelpScout();
    helpScout.showForcedHelpScoutMessages({
      type: 'claim',
      companyId,
      allotmentCycleId,
      categoryId,
    });
    return true;
  };

  static getFilteredAllotments = (
    allotments?: Allotment[],
    selectedCategory?: PerkCategory | null,
  ) =>
    selectedCategory
      ? allotments?.filter((allotment) =>
          allotment.perk_categories.find((perkCategory) =>
            selectedCategory.id ? perkCategory.id === +selectedCategory?.id : null,
          ),
        )
      : allotments;

  formatAllotments(allotments?: Allotment[]) {
    return allotments?.map((allotment) => ({
      label: allotment.cycle.name,
      id: allotment.id,
    }));
  }

  static getCategoryFromAllotmentById = (
    allotment: Allotment,
    categoryId: number,
  ): PerkCategory | undefined =>
    allotment?.perk_categories.find((category) => category.id === categoryId);

  static getSelectableCategories(allotments?: Allotment[]): PerkCategory[] {
    if (!allotments) {
      return [];
    }

    const availableCategories = allotments.flatMap((allotment) =>
      allotment.perk_categories.map((category) => category),
    );

    return SaveClaimSidePanelController.sortCategoriesByName(
      availableCategories.filter(
        (category, index) =>
          index === availableCategories.findIndex((other) => category.id === other.id),
      ),
    );
  }

  static sortAllotmentsByName(allotments: Allotment[]): Allotment[] {
    const sorted = [...allotments];

    sorted.sort((a, b) => {
      if (a.cycle.name.toLowerCase() < b.cycle.name.toLowerCase()) {
        return -1;
      }
      if (a.cycle.name.toLowerCase() > b.cycle.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    return sorted;
  }

  static sortCategoriesByName(categories: PerkCategory[]): PerkCategory[] {
    const sorted = [...categories];
    sorted.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return sorted;
  }

  static getExpenseStatusName = (status: ExpenseStatus | undefined) => {
    if (!status) return '';
    const {statusName} = EXPENSE_STATUS_INFO[status];
    return statusName;
  };

  static handleClose = (setOpen: (isOpen: boolean) => void) => {
    Analytics.sendEvent({
      action: EVENT_ACTION.CLICKED_CANCEL_EXPENSE,
      category: EVENT_CATEGORY.CLAIM_PAGE,
      label: EVENT_LABEL.CANCEL_CLAIM_SUBMISSION,
    });
    setOpen(false);
  };

  static handleSubmit = (
    payload: SaveClaimFormFieldValues,
    onSubmit: (payload: SaveClaimFormFieldValues) => void,
  ) => {
    onSubmit(payload);
    Analytics.sendEvent({
      action: EVENT_ACTION.SAVED_NEW_CLAIM,
      category: EVENT_CATEGORY.CLAIM_PAGE,
      label: EVENT_LABEL.SAVED_PERK_CLAIM,
    });
  };
}
