import React, {Dispatch, SetStateAction} from 'react';
import {ComptModal} from '@compt/common/compt-modal/compt-modal';
import {ComptForm} from '@compt/common/forms/compt-form/compt-form';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {useUpdateAccountSettingsMutation} from '@compt/app/services/api/accounts-slice';
import {FieldValues, useForm} from 'react-hook-form';
import {AccountSettingsUpdate, TimeZone, UserSession} from '@compt/types/account';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptCheckboxField} from '@compt/common/forms/compt-checkbox-field/compt-checkbox-field';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

interface UserAccountSettingsModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const getTimeZoneValue = (userData: UserSession, timeZoneCode: string) =>
  userData.timezones && userData.timezones.length > 0
    ? userData.timezones.find((tz: TimeZone) => tz.id === timeZoneCode)
    : '';

export const UserAccountSettingsModal = (props: UserAccountSettingsModalProps) => {
  const {open, setOpen} = props;

  const sessionQuery = useGetSessionQuery();
  const userData = sessionQuery.data;

  const [updateAccountSettings] = useUpdateAccountSettingsMutation();

  const formMethods = useForm({mode: 'onChange'});
  const {reset} = formMethods;

  if (!userData) {
    return null;
  }

  const fieldSections = [
    {
      fields: [
        {
          fieldType: ComptTextField,
          id: 'nickname',
          label: 'Preferred name',
          placeholder: 'Preferred Name',
          initialValue: userData?.nickname,
        },
        {
          fieldType: ComptTextField,
          id: 'email',
          label: 'Email*',
          initialValue: userData?.email,
          disabled: true,
        },
        {
          fieldType: ComptTextField,
          id: 'first_name',
          label: 'First name',
          initialValue: userData?.first_name,
          validation: {
            required: 'First name is required',
          },
        },
        {
          fieldType: ComptTextField,
          id: 'last_name',
          label: 'Last name',
          initialValue: userData?.last_name,
          validation: {
            required: 'Last name is required',
          },
        },
        {
          fieldType: ComptTextField,
          id: 'job_title',
          label: 'Job title',
          initialValue: userData?.job_title,
        },
        {
          fieldType: ComptDropDownField,
          id: 'time_zone_code',
          getKey: (tz: TimeZone) => tz.id,
          getDisplayText: (tz: TimeZone) => tz.label,
          label: 'Timezone',
          value: getTimeZoneValue(userData, userData?.time_zone_code),
          options: userData?.timezones,
          disabled: !userData?.user_may_set_timezone,
          by: (a: TimeZone, b: TimeZone) => a?.id === b?.id,
        },
        {
          fieldType: ComptCheckboxField,
          id: 'notification_channels',
          label: 'Notification Channels',
          options: [
            {id: 'email_notification', label: 'Email', initialValue: userData?.email_notification},
            ...(userData.can_enable_slack
              ? [
                  {
                    id: 'slack_notification',
                    label: 'Slack',
                    initialValue: userData?.slack_notification,
                  },
                ]
              : []),
          ],
        },
      ],
    },
  ];

  const handleSubmitAccountSettings = (form: FieldValues) => {
    const adjustedForm = form;

    if (userData?.user_may_set_timezone) {
      adjustedForm['user_timezone'] = form.time_zone_code?.id || userData.time_zone_code;
    } else {
      delete adjustedForm.user_timezone;
    }

    delete adjustedForm.time_zone_code;
    delete adjustedForm.email;

    const submission = {...adjustedForm} as AccountSettingsUpdate;
    updateAccountSettings(submission).then((data) => {
      if ('error' in data) {
        console.error('Error updating account settings', {error: data, submitted: submission});
        triggerCustomToast('error', 'There was a problem updating your account settings');
        return;
      }
      setOpen(false);
      triggerCustomToast('success', 'Successfully updated your account settings');
      reset();
    });
  };

  return (
    <ComptModal
      title="My Account"
      subtitle="Review and edit account details"
      open={open}
      setOpen={setOpen}
      showXButton={true}
    >
      <ComptForm
        fieldSections={fieldSections}
        onSubmit={handleSubmitAccountSettings}
        formMethods={formMethods}
      >
        <ComptButton
          data-testid="user-account-settings-submit-button"
          buttonType={ComptButtonType.PRIMARY}
          onClick={formMethods.handleSubmit(handleSubmitAccountSettings)}
        >
          Save Changes
        </ComptButton>
      </ComptForm>
    </ComptModal>
  );
};
