// Types
import {comptApiSlice, ComptRTKTags} from './api-slice';
import {
  EmployeePreApprovalRequest,
  EmployeePreApprovalRequestListResults,
  EmployeePreApprovalRequestPayload,
} from '@compt/types/learning-development/pre-approval-request';
import {EmployeeLearningDevelopmentBudgetListResults} from '@compt/types/learning-development/employee-learning-development-budget';
import {
  EmployeeReimbursementRequestPayload,
  ReimbursementRequest,
} from '@compt/types/learning-development/reimbursement-request';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEmployeePreApprovalRequests: builder.query<EmployeePreApprovalRequestListResults, number>(
      {
        query: (companyId) => `learning_and_development/${companyId}/me/pre_approval_requests`,
        providesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST],
      },
    ),
    getEmployeePreApprovalRequest: builder.query<
      EmployeePreApprovalRequest,
      {companyId: number | undefined; requestId: string | null}
    >({
      query: ({companyId, requestId}) =>
        `learning_and_development/${companyId}/me/pre_approval_requests/${requestId}`,
      providesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST],
    }),
    getAllEmployeeProgramBudgets: builder.query<
      EmployeeLearningDevelopmentBudgetListResults,
      number
    >({
      query: (companyId) => `learning_and_development/${companyId}/me/program_budget_eligibilities`,
      providesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PROGRAM_LIST],
    }),
    createEmployeePreApprovalRequest: builder.mutation<
      EmployeePreApprovalRequest,
      {
        body: EmployeePreApprovalRequestPayload;
        companyId: number;
      }
    >({
      query({body, companyId}) {
        return {
          url: `learning_and_development/${companyId}/me/pre_approval_requests/`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST],
    }),
    updateEmployeePreApprovalRequest: builder.mutation<
      EmployeePreApprovalRequest,
      {
        body: EmployeePreApprovalRequestPayload;
        companyId: number;
        requestId: number;
      }
    >({
      query({body, companyId, requestId}) {
        return {
          url: `learning_and_development/${companyId}/me/pre_approval_requests/${requestId}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST],
    }),
    createEmployeeReimbursementRequest: builder.mutation<
      ReimbursementRequest,
      {
        body: EmployeeReimbursementRequestPayload;
        companyId: number;
      }
    >({
      query({body, companyId}) {
        return {
          url: `learning_and_development/${companyId}/me/reimbursement_requests/`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST],
    }),
  }),
});

export const {
  useGetAllEmployeePreApprovalRequestsQuery,
  useGetEmployeePreApprovalRequestQuery,
  useGetAllEmployeeProgramBudgetsQuery,
  useCreateEmployeePreApprovalRequestMutation,
  useUpdateEmployeePreApprovalRequestMutation,
  useCreateEmployeeReimbursementRequestMutation,
} = extendedComptApiSlice;
