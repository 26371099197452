import React, {Dispatch, SetStateAction} from 'react';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';

interface ReviewReimbursementFormProps {
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

export const ReviewReimbursementForm = (props: ReviewReimbursementFormProps) => (
  <>
    <ComptSidePanel.Content className="p-400">
      <h1>WIP: implement Reimbursement form here</h1>
    </ComptSidePanel.Content>
    <ComptSidePanel.Footer>
      <div className="grid grid-flow-col gap-3 sm:justify-start w-full bg-white">
        {!props.editMode && (
          <>
            <ComptButton buttonType={ComptButtonType.PRIMARY}>Approve</ComptButton>
            <ComptButton
              buttonType={ComptButtonType.SECONDARY}
              onClick={() => props.setEditMode(true)}
            >
              Edit
            </ComptButton>
            <ComptButton buttonType={ComptButtonType.DESTRUCTIVE}>Reject</ComptButton>
          </>
        )}
        {props.editMode && (
          <>
            <ComptButton>Submit</ComptButton>
            <ComptButton
              buttonType={ComptButtonType.SECONDARY}
              onClick={() => props.setEditMode(false)}
            >
              Cancel
            </ComptButton>
          </>
        )}
      </div>
    </ComptSidePanel.Footer>
  </>
);
