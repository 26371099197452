import {comptApiSlice, ComptRTKTags} from '@compt/app/services/api/api-slice';
import {StipendExpense, StipendExpenseListResults} from '@compt/types/stipend-expense';
import {MutationTrigger} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

export type CreateStipendBody = Partial<StipendExpense> & {
  allotment_id: number;
  receipt_key: string;
  supporting_document_keys: string[];
};

export type StipendMutation = MutationTrigger<
  MutationDefinition<
    Partial<CreateStipendBody>,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      NonNullable<unknown>,
      FetchBaseQueryMeta
    >,
    ComptRTKTags,
    StipendExpense,
    'api'
  >
>;

/**
 * Add endpoints for stipend expenses (AKA claims, not to be confused with business expenses)
 */
const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStipendExpenses: builder.query<
      StipendExpenseListResults,
      {userId?: number; params?: Record<string, unknown> | null}
    >({
      providesTags: [ComptRTKTags.STIPEND_EXPENSES],
      query: ({userId, params}) => ({
        url: `users/${userId}/expenses`,
        params: {...params},
      }),
    }),
    getStipendExpense: builder.query<StipendExpense, string>({
      providesTags: [ComptRTKTags.STIPEND_EXPENSE],
      query: (expenseId) => `expenses/${expenseId}`,
    }),
    createStipendExpense: builder.mutation<StipendExpense, Partial<CreateStipendBody>>({
      invalidatesTags: [ComptRTKTags.STIPEND_ALLOTMENTS, ComptRTKTags.STIPEND_EXPENSES],
      query: (expense: Partial<CreateStipendBody>) => ({
        url: 'expenses',
        method: 'POST',
        body: expense,
      }),
    }),
    updateStipendExpense: builder.mutation<StipendExpense, Partial<StipendExpense>>({
      invalidatesTags: [
        ComptRTKTags.STIPEND_ALLOTMENTS,
        ComptRTKTags.STIPEND_EXPENSES,
        ComptRTKTags.STIPEND_EXPENSE,
      ],
      query: (expense: StipendExpense) => ({
        url: `expenses/${expense.id}`,
        method: 'PUT',
        body: expense,
      }),
    }),
    deleteStipendExpense: builder.mutation<StipendExpense, StipendExpense>({
      invalidatesTags: [ComptRTKTags.STIPEND_ALLOTMENTS, ComptRTKTags.STIPEND_EXPENSES],
      query: (expense: StipendExpense) => ({
        url: `expenses/${expense.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetStipendExpensesQuery,
  useGetStipendExpenseQuery,
  useCreateStipendExpenseMutation,
  useUpdateStipendExpenseMutation,
  useDeleteStipendExpenseMutation,
} = extendedComptApiSlice;
