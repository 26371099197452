import React, {FocusEventHandler, ReactElement} from 'react';
import {RegisterOptions, FormState, FieldValues, UseFormRegister, Control} from 'react-hook-form';
import {ComptFormErrors} from '@compt/common/forms/compt-form-errors/compt-form-errors';
import {ReactHookFormErrors} from '@compt/types/form/react-hook-form-helper-types';

export interface ComptFormFieldBaseProps {
  errors?: ReactHookFormErrors;
}

/**
 * @deprecated use one of the base interfaces instead.
 * @todo this will be addressed in COMPT-4264.
 *
 * @see ComptFormControlBaseProps
 * @see ComptFormControlSelectOptionBaseProps
 * @see ComptFormControlSingleSelectBaseProps
 * @see ComptFormControlFieldSingleSelectBaseProps
 * @see ComptFormControlMultiSelectBaseProps
 * @see ComptFormControlFieldMultiSelectBaseProps
 */
export interface DeprecatedComptFormFieldProps<
  TDataType = any,
  TFieldValues extends FieldValues = FieldValues,
  TName = string,
> extends ComptFormFieldBaseProps {
  validation?: RegisterOptions;
  /**
   * @deprecated pass errors directly instead.
   */
  formState?: FormState<FieldValues>;
  placeholder?: string;
  /**
   * The id of the input the Compt Form Field is labelling. This is passed directly to the label.
   *
   * NOTE: This is NOT parsed as a regular HTML ID for the purpose of DOM operations.
   */
  id: string;
  name?: TName;
  label?: string;
  initialValue?: TDataType | null;
  disabled?: boolean | undefined;
  excludeLabelText?: boolean | undefined;
  field?: ReactElement;
  register?: UseFormRegister<TFieldValues>;
  control?: Control<TFieldValues>;
  onBlur?: FocusEventHandler<HTMLElement> | undefined;
  stepValue?: string;
  decimalPlaces?: number;
  defaultCurrencyCode?: string;
  ['data-testid']?: string;
}

/**
 * @deprecated use one of the base interfaces instead.
 * @todo this will be addressed in COMPT-4264.
 *
 * @see ComptFormControlSingleSelectBaseProps
 * @see ComptFormControlFieldSingleSelectBaseProps
 * @see ComptFormControlMultiSelectBaseProps
 * @see ComptFormControlFieldMultiSelectBaseProps
 */
export interface ComptMultiOptionControlFieldProps<
  TDataType = any,
  TFieldValues extends FieldValues = FieldValues,
  TName = string,
  TOptionsType = TDataType[],
> extends DeprecatedComptFormFieldProps<TDataType, TFieldValues, TName> {
  options: TOptionsType;
  getKey: (option: TDataType) => string;
  getDisplayText: (option: TDataType) => string;
}

export interface ComptFormFieldProps<TName = string> {
  label?: string;
  subLabel?: string;
  id?: string;
  name?: TName;
  validation?: RegisterOptions;
  errors?: ReactHookFormErrors;
  field: ReactElement;
  ['data-testid']?: string;
  excludeLabelText?: boolean | undefined;
  className?: string;
  isCheckbox?: boolean;
  isFileUpload?: boolean;
  readOnly?: boolean;
}
export const ComptFormField = (props: ComptFormFieldProps) => {
  const {field} = props;
  // Set readOnly default to false if no props are passed in
  const readOnly = props.readOnly ?? false;

  return (
    <div className={`${props.isCheckbox || props.isFileUpload ? '' : 'mb-400'} ${props.className}`}>
      {!props.excludeLabelText && props.label && (
        <label
          htmlFor={props.id}
          data-testid={`${props['data-testid']}-label`}
          className="flex items-center body2 text-color-body1 mb-1"
        >
          {`${props.label} `}
          {props.validation?.required && !readOnly && (
            <p className="text-stroke-critical ml-0.5">*</p>
          )}
          {!props.validation?.required && !readOnly && (
            <p className="text-sm font-medium text-gray-400">&nbsp;(optional)</p>
          )}
        </label>
      )}
      {props.subLabel && <p className="block body3 text-color-body2 mb-100">{props.subLabel}</p>}
      <div
        className={`sm:col-span-2 sm:mt-0 flex flex-col ${
          props.isFileUpload ? 'h-[528px]' : 'h-full'
        }`}
      >
        {field}
        {props.errors && (
          <div className="mt-1.5">
            <ComptFormErrors
              errors={props.errors}
              label={props.label}
              errorTestId={`${props['data-testid']}-error`}
            />
          </div>
        )}
      </div>
    </div>
  );
};
